<template>
  <div class="equipment-action">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" :content="content" />
    <ml-tip class="scroll-box" :showHeader="false" style="border: 0">
      <el-scrollbar :height="tabberHeight(185)">
        <!-- 表单 -->
        <ml-form
          ref="equipmentFormRef"
          :disabled="!!viewId"
          labelWidth="200px"
          style="width: 1200px; margin: 20px auto 0"
          :model="equipmentForm"
          :rules="equipmentFormRules"
        >
          <!-- 提示标题 -->
          <el-divider content-position="left" style="font-weight: bold">设备信息</el-divider>
          <el-row>
            <el-col :span="12">
              <ml-select
                prop="companyId"
                placeholder="请选择阿米巴"
                :options="unitOptions"
                label="阿米巴:"
                keyName="companyId"
                labelName="companyName"
                valueName="companyId"
                v-model="equipmentForm.companyId"
                @change="setCompany"
              />
            </el-col>
            <el-col :span="12">
              <ml-input
                prop="deviceName"
                label="设备名称:"
                placeholder="请输入设备名称"
                v-model.trim="equipmentForm.deviceName"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <ml-select
                prop="propertyType"
                placeholder="请选择资产类别"
                :options="propertyTypeList"
                label="资产类别:"
                keyName="label"
                labelName="label"
                valueName="label"
                v-model="equipmentForm.propertyType"
                @change="propertyTypeChange"
              />
            </el-col>
            <el-col :span="12">
              <ml-select
                prop="deviceType"
                placeholder="请选择设备类型"
                :options="deviceTypeList"
                label="设备类型:"
                keyName="label"
                labelName="label"
                valueName="label"
                v-model="equipmentForm.deviceType"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <ml-input
                prop="propertyCode"
                label="资产编号:"
                placeholder="自动生成"
                disabled
                v-model.trim="equipmentForm.propertyCode"
              />
            </el-col>
            <el-col :span="12">
              <!-- 所属项目 -->
              <ml-select
                prop="projectId"
                placeholder="请选择所属项目"
                :options="projectData"
                label="所属项目:"
                keyName="projectId"
                labelName="projectName"
                valueName="projectId"
                v-model="equipmentForm.projectId"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <ml-input
                prop="firstUnit"
                label="所属一级单位:"
                placeholder="请输入所属一级单位"
                v-model.trim="equipmentForm.firstUnit"
              />
            </el-col>
            <el-col :span="12">
              <ml-input
                prop="secondUnit"
                label="所属二级单位:"
                placeholder="请输入所属二级单位"
                v-model.trim="equipmentForm.secondUnit"
              />
            </el-col>
          </el-row>

          <el-row>
            <!-- <el-col :span="12">
              <ml-cascader
                prop="areaArr"
                :props="{ label: 'name', value: 'name' }"
                clearable
                label="设备区域:"
                :options="areaData"
                placeholder="请选择设备区域"
                v-model="equipmentForm.areaArr"
                style="width: 100%"
              />
            </el-col> -->
            <el-col :span="12">
              <ml-input
                :maxlength="20"
                prop="ipAddress"
                label="固定IP:"
                placeholder="请输入固定IP"
                v-model.trim="equipmentForm.ipAddress"
              />
            </el-col>
            <el-col :span="12">
              <ml-input
                prop="address"
                label="安装位置:"
                placeholder="请输入安装位置"
                v-model.trim="equipmentForm.address"
              />
            </el-col>
          </el-row>

          <el-row v-if="equipmentForm.monitorStatus === 'Y'">
            <el-col :span="7">
              <ml-input
                prop="longitude"
                label="经度:"
                placeholder="请输入经度"
                v-model.trim="equipmentForm.longitude"
              />
            </el-col>
            <el-col :span="5">
              <ml-input
                prop="latitude"
                label="纬度:"
                placeholder="请输入纬度"
                labelWidth="100px"
                v-model.trim="equipmentForm.latitude"
              />
            </el-col>
            <el-col :span="12">
              <!-- <el-button type="text" style="margin-left: 20px" @click="goTmapIndex">
                获取经纬度
              </el-button> -->
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <ml-input
                prop="model"
                label="规格/型号:"
                placeholder="请输入规格/型号:"
                v-model.trim="equipmentForm.model"
              />
            </el-col>
            <el-col :span="12">
              <ml-input-number
                prop="deviceCount"
                label="数量:"
                :min="0"
                placeholder="请输入数量"
                v-model="equipmentForm.deviceCount"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <ml-input
                prop="manufacturer"
                label="生产厂商:"
                placeholder="请输入生产厂商:"
                v-model.trim="equipmentForm.manufacturer"
              />
            </el-col>
            <el-col :span="12">
              <ml-input-number
                prop="price"
                label="采购价(元):"
                placeholder="请输入采购价"
                v-model="equipmentForm.price"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <ml-date-picker
                prop="productDate"
                placeholder="请选择生产日期"
                label="生产日期:"
                type="date"
                v-model="equipmentForm.productDate"
              />
            </el-col>
            <el-col :span="12">
              <ml-input-number
                prop="warranty"
                label="质保期(年):"
                :min="0"
                :max="100"
                placeholder="请输入质保期"
                v-model="equipmentForm.warranty"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <ml-date-picker
                prop="joinDate"
                placeholder="请选择进场日期"
                label="进场日期:"
                type="date"
                v-model="equipmentForm.joinDate"
              />
              <!-- <ml-input
                prop="repairDesc"
                label="维修记录:"
                placeholder="请输入维修记录"
                v-model="equipmentForm.repairDesc"
              /> -->
            </el-col>
            <el-col :span="12">
              <ml-select
                prop="propertyStatus"
                placeholder="请选择资产状态"
                :options="propertyStatusData"
                label="资产状态:"
                keyName="label"
                labelName="label"
                valueName="label"
                v-model="equipmentForm.propertyStatus"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <ml-input
                prop="videoPath"
                label="iot平台设备编码:"
                placeholder="请输入iot平台设备编码:"
                v-model.trim="equipmentForm.iotDeviceCode"
              />
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="是否监控" prop="monitorStatus">
                <el-radio v-model="equipmentForm.monitorStatus" label="Y">是</el-radio>
                <el-radio v-model="equipmentForm.monitorStatus" label="N">否</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <ml-input
                v-if="equipmentForm.deviceType === '监视设备'"
                prop="videoPath"
                label="视频地址:"
                placeholder="请输入视频地址:"
                v-model.trim="equipmentForm.videoPath"
              />
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="设备图片:">
                <ml-upload
                  autoUpload
                  :action="action"
                  list-type="picture-card"
                  :limit="1"
                  :data="{ linkType: 'error' }"
                  :headers="{ Authorization: toKen }"
                  tip="请上传宽300px,高150px的比例图片，最优大小100kB以内！"
                  name="files"
                  :fileLists="imgList"
                  :disabled="!!viewId"
                  @on-success="onSuccess"
                  @on-remove="removeHandle"
                >
                </ml-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12"> </el-col>
          </el-row>

          <ml-input
            prop="remark"
            label="描述信息："
            placeholder="请输入描述信息"
            type="textarea"
            :rows="5"
            v-model.trim="equipmentForm.remark"
            :maxlength="200"
            showWordLimit
          />
          <!-- 查看二维码 -->
          <div class="goBack-box" v-if="!!updateId || !!viewId">
            <el-image
              v-if="newQrcode"
              style="width: 150px; height: 150px"
              :src="newQrcode"
              hide-on-click-modal
            />
          </div>

          <ml-button
            submitText="提交"
            submitIcon="el-icon-upload2"
            cancelIcon="el-icon-circle-close"
            cancelText="返回上一页"
            native-type="button"
            v-if="!viewId"
            @click-submit="clickSubmitUser"
            @click-cancel="clickCancelUser"
          />
        </ml-form>
        <div class="goBack-box" v-if="!!viewId" style="margin-top: 0">
          <el-button size="medium" icon="el-icon-circle-close" @click="clickCancelUser">
            返回上一页
          </el-button>
        </div>
      </el-scrollbar>
    </ml-tip>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getTabberData, tabberHeight, getPropertyTypeList, searchParams, formetData } from '@/utils'
import { BASEURL, FILEUPLOADURL, DEVICEQRCODE } from '@API'

export default {
  name: 'EquipmentAction',
  components: {},
  setup() {
    const { commit, getters, dispatch } = useStore()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    const toKen = computed(() => getters.getToken)
    const route = useRoute()
    const router = useRouter()
    // 获取路径参数
    const { updateId, viewId } = route.query
    // 设置导航标题文本
    const content = ref(updateId ? '修改设备' : viewId ? '查看设备' : '添加设备')
    /* 开始 表单 **********************************/

    // 表单配置
    const equipmentFormRef = ref()
    const installerPicsRef = ref()
    const equipmentForm = reactive({
      companyId: '',
      deviceName: '',
      propertyType: '',
      deviceType: '',
      propertyCode: '',
      projectId: '',
      firstUnit: '',
      secondUnit: '',
      ipAddress: '',
      address: '',
      longitude: '',
      latitude: '',
      model: '',
      deviceCount: 0,
      manufacturer: '',
      price: 0,
      productDate: '',
      warranty: '',
      joinDate: '',
      imagePath: '',
      // repairDesc: '',
      propertyStatus: '',
      monitorStatus: 'N',
      videoPath: '',
      remark: '',
      qrCode: '',
      areaArr: [],
      deviceId: '',
      iotDeviceCode: ''
    })

    const newQrcode = ref('')
    watch(
      () => equipmentForm.deviceId,
      newvalue => {
        if (newvalue) {
          newQrcode.value = `${DEVICEQRCODE}/${newvalue}`
        }
      }
    )

    // 单位名下拉列表
    const unitOptions = ref([])
    // 管理区域配置
    const areaData = ref([])
    // 所属项目下拉列表
    const projectData = ref([])
    // 资产状态
    const propertyStatusData = ref([{ label: '正常' }, { label: '损坏' }, { label: '过期' }])

    // 资产类型
    const propertyTypeList = ref(getPropertyTypeList())
    // 设备类型
    const deviceTypeList = ref([])
    const propertyTypeChange = () => {
      equipmentForm.deviceType = ''
    }
    // 监听资产类型变化
    watch(
      () => equipmentForm.propertyType,
      newvalue => {
        deviceTypeList.value = []
        if (newvalue) {
          const newPropertyTypeList = propertyTypeList.value.filter(item => item.label === newvalue)
          deviceTypeList.value = newPropertyTypeList[0].value
        }
      }
    )
    watch(
      () => equipmentForm.deviceType,
      newvalue => {
        if (newvalue != '监视设备') {
          equipmentForm.videoPath = ''
        }
      }
    )
    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }
    const setCompany = () => {
      equipmentForm.projectId = ''
      equipmentForm.areaArr = []
    }
    // 处理区域
    const getAreaData = id => {
      return (
        unitOptions.value.filter(item => id === item.companyId).map(item => item.regions)[0] || []
      )
    }
    watch(
      () => equipmentForm.companyId,
      newvalue => {
        getProjectData(newvalue)
        areaData.value = getAreaData(newvalue)
      }
    )
    // const videoPathValidate = () => {
    //   return equipmentForm.deviceType === '采集设备' && !equipmentForm.videoPath ? false : true
    // }
    const longitudeValidate = () => {
      return equipmentForm.monitorStatus === 'Y' && !equipmentForm.longitude ? false : true
    }
    const latitudeValidate = () => {
      return equipmentForm.monitorStatus === 'Y' && !equipmentForm.latitude ? false : true
    }
    // const imagePathValidate = () => {
    //   return equipmentForm.monitorStatus === 'Y' && !equipmentForm.imagePath ? false : true
    // }
    // 表单校验
    const equipmentFormRules = {
      companyId: [{ required: true, message: '请选择阿米巴', trigger: 'blur' }],
      deviceName: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
      propertyType: [{ required: true, message: '请输入资产类别', trigger: 'blur' }],
      deviceType: [{ required: true, message: '请输入设备类型', trigger: 'blur' }],
      areaArr: [{ required: true, message: '请选择设备区域', trigger: 'blur' }],
      projectId: [{ required: true, message: '请选择所属项目', trigger: 'blur' }],
      address: [{ required: true, message: '请输入安装位置', trigger: 'blur' }],
      longitude: [
        { required: true, validator: longitudeValidate, message: '请输入经度', trigger: 'blur' }
      ],
      latitude: [
        { required: true, validator: latitudeValidate, message: '请输入纬度', trigger: 'blur' }
      ],
      propertyStatus: [{ required: true, message: '请选择资产状态', trigger: 'blur' }],
      monitorStatus: [{ required: true, message: '请选择是否监控', trigger: 'blur' }],
      imagePath: [{ required: true, message: '请输入图片地址', trigger: 'blur' }]
      // videoPath: [
      //   {
      //     required: true,
      //     validator: videoPathValidate,
      //     message: '请输入视频地址址',
      //     trigger: 'blur'
      //   }
      // ]
    }
    // 提交表单
    const clickSubmitUser = async () => {
      equipmentFormRef.value.CustomFormRef.validate()
        .then(() => {
          const equipmentParams = searchParams(
            equipmentForm,
            ['productDate', 'propertyCode', 'areaArr', 'joinDate'],
            true
          )
          if (equipmentForm.productDate) {
            equipmentParams.productDate = formetData(equipmentForm.productDate, 'year')
          }
          if (equipmentForm.joinDate) {
            equipmentParams.joinDate = formetData(equipmentForm.joinDate, 'year')
          }
          if (equipmentForm.areaArr.length > 0) {
            const [province, city, area] = equipmentForm.areaArr
            equipmentParams.province = province
            equipmentParams.city = city
            equipmentParams.area = area
          }
          if (content.value === '添加设备') {
            dispatch('fetchAddDeviceInfo', equipmentParams).then(data => {
              if (data && data.code === 200) {
                commit('setError', {
                  status: true,
                  title: data.message,
                  message: '正在获取新的数据...',
                  type: 'success'
                })
                router.push({ path: '/equipmentManage' })
              }
            })
          } else if (content.value === '修改设备') {
            equipmentParams.deviceId = updateId
            dispatch('fatchUpdateDeviceInfo', equipmentParams).then(data => {
              if (data && data.code === 200) {
                commit('setError', {
                  status: true,
                  title: data.message,
                  message: '正在获取新的数据...',
                  type: 'success'
                })
                router.push({ path: '/equipmentManage' })
              }
            })
          }
        })
        .catch(() => {})
    }
    // 取消表单
    const clickCancelUser = () => {
      router.push({ path: '/equipmentManage' })
    }
    // 跳百度地图得利经纬度
    const getLtitudeAndLongitudeRef = ref()
    const amapSearchValue = ref('')
    // 打开地图窗口
    const goMaps = () => {
      getLtitudeAndLongitudeRef.value.showDialog = true
    }
    // 设置选择经纬度
    const setPoints = point => {
      equipmentForm.longitude = point.lng
      equipmentForm.latitude = point.lat
      getLtitudeAndLongitudeRef.value.showDialog = false
    }

    // 查看二维码
    const clickCreateDeviceQrCode = () => {
      const { companyId, propertyCode } = equipmentForm
      dispatch('fetchCreateDeviceQrCode', { companyId, propertyCode }).then(data => {
        if (data && data.code === 200 && data.data) {
          equipmentForm.qrCode = data.data
        }
      })
    }

    const getEquipmentDetails = async () => {
      const equipmentId = updateId || viewId
      await dispatch('fetchGetDeviceInfo', equipmentId).then(data => {
        if (data && data.code === 200) {
          if (data.data.imagePath) {
            imgList.value = [
              {
                name: data.data.imagePath,
                url: data.data.imagePath
              }
            ]
          }
          // 设备信息
          Object.keys(equipmentForm).map(item => {
            if (item === 'areaArr') {
              // const { province, city, area } = data.data
              // equipmentForm.areaArr = [province, city, area]
            } else {
              equipmentForm[item] = data.data[item] || ''
            }
          })
        }
      })
    }

    // 跟天地图线上页面
    const goTmapIndex = () => {
      open('https://map.tianditu.gov.cn/')
    }

    // 上传附件
    const imgList = ref([])
    const action = ref(`${BASEURL}${FILEUPLOADURL}`)
    const onSuccess = files => {
      if (files.data && files.data.length > 0) {
        equipmentForm.imagePath = files.data[0].filePath
        imgList.value = [
          {
            name: files.data[0].filePath,
            url: files.data[0].filePath
          }
        ]
      } else {
        equipmentForm.imagePath = ''
        imgList.value = []
      }
    }
    const removeHandle = file => {
      equipmentForm.imagePath = ''
      imgList.value = []
    }

    /* 结束 用户表单 **********************************/
    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {})
      unitOptions.value = data || []
      if (data.length > 0) {
        equipmentForm.companyId = data[0].companyId
      }
      if (updateId || viewId) {
        await getEquipmentDetails()
      }
    })
    return {
      content,
      equipmentForm,
      unitOptions,
      projectData,
      equipmentFormRules,
      clickSubmitUser,
      clickCancelUser,
      goMaps,
      viewId,
      updateId,
      propertyStatusData,
      getLtitudeAndLongitudeRef,
      amapSearchValue,
      setPoints,
      installerPicsRef,
      tabberHeight,
      propertyTypeList,
      deviceTypeList,
      equipmentFormRef,
      setCompany,
      clickCreateDeviceQrCode,
      newQrcode,
      propertyTypeChange,
      areaData,
      goTmapIndex,
      toKen,
      action,
      onSuccess,
      imgList,
      removeHandle
    }
  }
}
</script>

<style lang="scss">
.equipment-action {
  position: relative;
  .ml-tree {
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding: 10px 15px;
    .el-tree {
      > .el-tree-node {
        > .el-tree-node__children {
          > .el-tree-node {
            > .el-tree-node__children {
              white-space: break-spaces;
              > .el-tree-node {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
  .picTab {
    .el-tabs__nav-wrap::after {
      background-color: #fff !important;
    }
    .el-tabs__nav-scroll {
      padding: 0 40px;
    }
  }
  .man-class {
    display: inline-block;
    min-width: 100px;
  }
}
.ltitude-and-longitude {
  .el-dialog__body {
    padding: 0 !important;
  }
  .el-dialog__header {
    display: none;
  }
}
</style>
